import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { LINKS } from "../../config";
import { toast } from "react-hot-toast";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    documento_key: string;
    documento_nome: string;
    usuario_id: number;
}

export const ModalAdicionarDocumento = (props: Props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [newDocumentName, setNewDocumentName] = useState<string>("");

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append("documento", file as File);
            formData.append("documento_nome", props.documento_nome);
            formData.append("documento_key", props.documento_key);
            if (props.documento_key === "outros_documentos") {
                formData.append("new_document_name", newDocumentName);
            }
            setIsLoading(true);

            axios.post(`${LINKS.API}/profile/${props.usuario_id}/documents`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    props.onClose();

                    setTimeout(() => {
                        window.location.reload();
                    }, 500);

                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error("Erro ao enviar o arquivo.");
            }).finally(() => {
                setIsLoading(false);

            });
        } else {
            toast.error("Selecione um arquivo para enviar.");
        }
    }

    return (
        <Modal show={props.isOpen} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar {props.documento_nome}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    {
                        props.documento_key === "outros_documentos" &&
                        <Form.Group className="mb-3">
                            <Form.Label>Nome do documento</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome do documento"
                                required
                                value={newDocumentName}
                                onChange={(e) => setNewDocumentName(e.target.value)}
                            />
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>Arquivo</Form.Label>
                        <input
                            className="form-control"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFile(e.target.files[0]);
                                }
                            }}
                            type="file"
                        />
                    </Form.Group>
                    <Form.Text className="text-muted">Caso já exista um documento com o mesmo nome, ele será substituído.</Form.Text>
                    <Button disabled={isLoading ? true : false} variant="primary" type="submit" className="float-end mt-3">
                        {isLoading ? "Enviando..." : "Enviar"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
