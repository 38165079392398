import { Modal, Button, Form } from "react-bootstrap";
import { IBaseCustomer } from "../../interfaces/IBase";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";

interface Props {
    customer: IBaseCustomer;
    show: boolean;
    onHide: () => void;
    onUpdate: (customer: IBaseCustomer) => void;
}

export const ModalUpdateCustomer = ({ show, customer, onHide, onUpdate }: Props) => {

    const [name, setName] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [age, setAge] = useState<number>(0);

    useEffect(() => {
        if (show) {
            setName(customer.name);
            setCpf(customer.cpf);
            setPhone(customer.phone);
            setAge(Number(customer.age));
            console.log(customer);
        }
    }, [show, customer]);

    const submitUpdate = () => {
        axios.put(`${LINKS.API}/customers/${customer.id}`, { name, cpf, phone, age }).then(response => {
            if (response.data.status === 200) {
                onUpdate(response.data.data);
                toast.success(response.data.message);
                onHide();
            } else {
                console.error(response.data.message);
                toast.error(response.data.message);
            }
        }).catch(error => {
            console.error(error);
            toast.error('Erro ao editar Cliente');
        });
    }

    return (
        <Modal show={show} onHide={onHide} closeButton>
            <Modal.Header>
                <Modal.Title>Editar Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            className="mb-2"
                            type="text"
                            placeholder="Nome"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicCpf">
                        <Form.Label>CPF</Form.Label>
                        <PatternFormat
                            format="###.###.###-##"
                            value={cpf}
                            onValueChange={(values) => {
                                const { value } = values;
                                setCpf(value);
                            }}
                            className="form-control mb-2"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPhone">
                        <Form.Label>Telefone</Form.Label>

                        <PatternFormat
                            format="(##) #####-####"
                            value={phone}
                            onValueChange={(values) => {
                                const { formattedValue } = values;
                                setPhone(formattedValue);
                            }}
                            className="form-control mb-2"
                            placeholder="Telefone"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicAge">
                        <Form.Label>Idade</Form.Label>
                        <Form.Control
                            className="mb-2"
                            type="number"
                            placeholder="Idade"
                            value={age}
                            onChange={(e) => setAge(Number(e.target.value))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={submitUpdate}>
                    Editar
                </Button>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}