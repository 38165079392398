import { Modal } from "react-bootstrap"
import { ILog } from "../../interfaces/ILog"
import parse from 'html-react-parser';
import styles from "../../styles/Logs.module.scss";
interface Props {
    log: ILog | undefined,
    isOpen: boolean,
    onClose: () => void
}

export const ViewLog = ({ log, isOpen, onClose }: Props) => {
    return (
        <Modal show={isOpen} onHide={onClose} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{log?.log}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.scroll_y}>
                <div className="container">
                    {log?.data && parse(log?.data)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onClose} className="btn btn-secondary">Fechar</button>
            </Modal.Footer>
        </Modal>
    )
}