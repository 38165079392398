import classNames from 'classnames';
import styles from '../styles/Loader.module.scss';

interface Props {
    size?: string;
    color?: string;
    classes?: string;
}

export const Loader = ({ size, color, classes }: Props) => {
    return (
        <span
            className={classNames(styles.loader, classes ? classes : '')}
            style={{ width: size, height: size, borderBottomColor: color }}
        ></span>
    );
};
