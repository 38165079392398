import axios from "axios";
import { Button, Modal } from "react-bootstrap"
import { IoTrashOutline } from "react-icons/io5"
import { INotification } from "../../interfaces/INotification";
import { LINKS } from "../../config";
import toast from "react-hot-toast";

interface Props {
    notification: INotification;
    isOpen: boolean;
    onClose: () => void;
    onDelete: (notification: INotification) => void;
}

export const ModalDeleteNotification = ({ notification, isOpen, onClose, onDelete }: Props) => {

    const confirmDelete = () => {
        axios.delete(`${LINKS.API}/notifications/${notification.id}`).then(response => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                onDelete(notification);
            } else {
                toast.error('Erro ao excluir notificação');
            }
            onClose();
        }).catch(error => {
            toast.error('Erro ao excluir notificação');
            console.error(error);
        });
    };

    if (!notification) return null;

    return (

        <Modal show={isOpen} onHide={onClose} closeButton>
            <Modal.Header>
                <Modal.Title>Excluir Notificação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Deseja realmente excluir a notificação <strong>#{notification.id}</strong>?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancelar
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                    Excluir <IoTrashOutline />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
