import { useEffect, useState } from "react";
import { FaBell, FaCheck, FaExternalLinkAlt } from "react-icons/fa";
import styles from "../../styles/NavBar.module.scss";
import { Modal, Table } from "react-bootstrap";
import { INotification } from "../../interfaces/INotification";
import axios from "axios";
import { LINKS } from "../../config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import toast from "react-hot-toast";
import { IoReload } from "react-icons/io5";
import { Loader } from "../Loader";
import { useSelector } from "react-redux";

interface Props {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export const Notifications = ({ isOpen, setIsOpen }: Props) => {

    const [notifications, setNotifications] = useState<INotification[]>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isFetching, setIsFetching] = useState(false);

    const [reload, setReload] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        if (isFetching) return;
        setIsFetching(true);

        axios.get(`${LINKS.API}/notifications/${currentUser.id}`).then(response => {
            if (response.data.status === 200) {
                setNotifications(response.data.data);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
            setIsFetching(false);
        }).catch((e) => { console.log(e); setIsFetching(false); });
    }, [reload, dispatch, navigate, isOpen]);

    const handleRead = (id: number) => {
        setNotifications((lastNotifications) => lastNotifications.map((notification) => {
            if (notification.id === id) {
                return {
                    ...notification,
                    is_read: notification.is_read === 1 ? 0 : 1
                };
            }
            return notification;
        }));

        axios.put(`${LINKS.API}/notifications/${id}/read`).then(response => {
            if (response.data.status !== 200) {
                toast.error(response.data.message);
            }
        }).catch((e) => { console.log(e); });

    };

    // const handleDelete = (id: number) => {
    //     setNotifications((lastNotifications) => lastNotifications.filter((notification) => notification.id !== id));
    // };

    return (
        <div className={styles.notifications}>
            <div onClick={() => setIsOpen(!isOpen)}>
                <FaBell className={styles.bell} />
                {
                    notifications.filter((n) => !n.is_read).length > 0 && (
                        <span className={styles.badge}>{notifications.filter((n) => !n.is_read).length}</span>
                    )
                }
            </div>
            {isOpen && (
                <Modal scrollable show={true} onHide={() => setIsOpen(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Notificações
                            {
                                isFetching ? (
                                    <Loader classes="mt-2 mx-2" size="30px" color="black" />

                                ) : (
                                    <IoReload onClick={() => setReload((oldReload) => !oldReload)} cursor={"pointer"} className="mb-2 mx-2" />
                                )
                            }
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        {notifications.length === 0 ? (
                            <p className={"date"}>Sem notificações.</p>
                        ) : (<>
                            <Table>
                                <tbody>
                                    {notifications.map((notification) => (
                                        <tr key={notification.id} className={notification.is_read ? styles.read : styles.no_read}>
                                            <td>
                                                <p className={styles.message}>{notification.message}</p>
                                            </td>
                                            {
                                                notification.link ? (
                                                    <td className={styles.actions} title="Ir ao link da notificação">
                                                        <a href={notification.link} target="_blank" rel="noreferrer">
                                                            <FaExternalLinkAlt />
                                                        </a>
                                                    </td>
                                                ) : <td className={styles.actions}></td>
                                            }
                                            < td
                                                className={styles.actions}
                                                title={`Marcar como ${notification.is_read ? "não " : ""}lida`}
                                            >
                                                <FaCheck onClick={() => handleRead(notification.id)} />
                                            </td>
                                            {/* <td
                                                className={styles.actions}
                                                title="Apagar notificação"
                                            >
                                                <FaTrash onClick={() => handleDelete(notification.id)} />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>)}
                    </Modal.Body>
                </Modal >
            )
            }
        </div >
    );
};
