import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LINKS, formatCpf } from "../../config";
import { IBase, IBaseCustomer } from "../../interfaces/IBase";
import styles from "../../styles/Bases.module.scss";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseCustomerDetails } from "./BaseCustomerDetails";

interface Props {
    customer: IBaseCustomer;
    isOpenDetails: boolean;
    setOpenDetailId: (id: number | null) => void;
    base: IBase
    hideModal: () => void;
}

export const BaseRow = ({ customer, isOpenDetails, setOpenDetailId, base, hideModal }: Props) => {
    const [localCustomer, setLocalCustomer] = useState(customer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);

    const [isLoadingCheckbox, setIsLoadingCheckbox] = useState(false);

    const [inputMode, setInputMode] = useState<"text" | "input">("text");

    const handleConfirmPhone = () => {
        axios.put(`${LINKS.API}/customers/${customer.id}/phone`, {
            phone: localCustomer.phone
        }).then(response => {
            if (response.data.status === 200) {
                setLocalCustomer({
                    ...localCustomer,
                    phone: response.data.data.phone
                });
                toast.success(response.data.message);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
            handlePhoneInputMode();

        }).catch(error => {
            console.log(error);
        })
    };

    const handlePhoneInputMode = () => {
        setInputMode((lastMode) => lastMode === "text" ? "input" : "text");
    };

    const handleOpenDetails = () => {
        setOpenDetailId(isOpenDetails ? null : customer.id);
    };

    const formatMargin = (margin: string) => {
        const numberMargin = parseFloat(margin);
        if (isNaN(numberMargin)) return margin;

        return numberMargin.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    };

    const handleToggleCheck = () => {
        setIsLoadingCheckbox(true);

        axios.put(`${LINKS.API}/bases/customercheck/handle`, {
            customer_id: localCustomer.id,
            base_id: base.id
        }).then(response => {
            if (response.data.status === 200) {
                setLocalCustomer({
                    ...localCustomer,
                    checked: response.data.data.checked
                });
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
                console.log(response.data.message);
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setIsLoadingCheckbox(false);
        });
    }

    return (
        <tr
            className={(localCustomer.checked === 1) ? styles.customer_checked : ""}
        >
            <td>
                {
                    currentUser.type !== "vendedor" ? (
                        localCustomer.id
                    ) :
                        (
                            // If the user is a seller, the customer id becomes a check input (checkbox)
                            <input type="checkbox"
                                checked={localCustomer.checked === 1}
                                onChange={handleToggleCheck}
                                disabled={isLoadingCheckbox}
                            />
                        )
                }
            </td>
            <td>
                {
                    currentUser.type !== "vendedor" ? (
                        <Link to={`/clientes/${localCustomer.id}`} className={styles.customer_name}>
                            {localCustomer.name}
                        </Link>
                    ) : (
                        <span className={styles.customer_name}>
                            {localCustomer.name}
                        </span>
                    )
                }
            </td>
            <td>{formatCpf(localCustomer.cpf)}</td>
            <td className="text-center">{localCustomer.age !== '0' ? localCustomer.age : ""}</td>

            <td>
                {
                    inputMode === "text" ? (
                        localCustomer.phone
                    ) : (<>
                        <input
                            type="text"
                            value={localCustomer.phone}
                            onChange={(e) => setLocalCustomer({ ...localCustomer, phone: e.target.value })}
                        />
                        <button onClick={handleConfirmPhone} className="px-1"><FaCheck color="green" /></button>
                    </>)
                }
            </td>

            <td className={styles.text_right}>{formatMargin(localCustomer.margin)}</td>
            <td title={!localCustomer.card_margin ? "Não consultado" : ""} className={styles.text_right}>{localCustomer.card_margin ? formatMargin(localCustomer.card_margin) : "N/C"}</td>
            <td className="text-center">
                {
                    localCustomer.closed_proposal === 1 ? <FaCheck color="green" /> : <IoCloseSharp color="red" />
                }
            </td>
            <td className="text-center">
                {
                    localCustomer.lose_margin === 1 && <FaCheck color="green" />
                }
                {
                    localCustomer.lose_margin === 0 && <IoCloseSharp color="red" />
                }
            </td>

            <BaseCustomerDetails
                customer={localCustomer}
                setLocalCustomer={setLocalCustomer}
                isOpenDetails={isOpenDetails}
                setOpenDetailId={setOpenDetailId}
                hideModal={hideModal}
                base={base}
                handleOpenDetails={handleOpenDetails}
                inputMode={inputMode}
                handlePhoneInputMode={handlePhoneInputMode}
            />
        </tr>
    );
};
