import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { LINKS } from "../../config";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { ModalNewUser } from "../../components/Users/ModalNewUser";
import { Table } from "react-bootstrap";
import { User } from "../../components/Users/User";
import { Loader } from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../interfaces/IUser";
import { ModalUpdateUser } from "../../components/Users/ModalUpdateUser";

export const Users = () => {
    const token = useSelector(selectCurrentToken);
    const [data, setData] = useState<IUser[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [filtro, setFiltro] = useState("");
    const [filteredData, setFilteredData] = useState<IUser[]>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState<IUser | null>(null);

    const handleUpdateModal = (user: IUser) => {
        console.log(user);
        if (!user) return;
        if (user.type !== "robot") {
            setUserToUpdate(user);
            setShowModalUpdate(true);
        }
    }

    useEffect(() => {
        setFilteredData(data.filter(u => {
            const user = `${u.name.trim()} ${u.last_name.trim()} ${u.type.trim()}`;
            if (filtro === "") return true;
            return user.toLowerCase().includes(filtro.toLowerCase());
        }));
    }, [filtro, data]);

    useEffect(() => {
        dispatch(closeSidebar());

        const fetchData = async () => {
            try {
                const response = await axios.get(`${LINKS.API}/users`);
                if (response.data.status === 200) {
                    setData(response.data.data);
                    setFilteredData(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
                toast.error("Erro ao carregar usuários");
            }
        };
        fetchData();
    }, [dispatch, navigate, token]);

    const handleModalClose = () => setShowModal(false);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Usuários</h3>
                <FiPlusCircle
                    size={21}
                    title="Novo Usuário"
                    color="black"
                    style={{ marginBottom: "10px" }}
                    onClick={() => setShowModal(true)}
                    cursor="pointer"
                />
            </div>
            <div className="w-100 mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filtrar por nome ou tipo"
                    value={filtro}
                    onChange={(e) => setFiltro(e.target.value)}
                />
            </div>
            <ModalNewUser
                show={showModal}
                onHide={handleModalClose}
                setNewUser={(newUser: IUser) => {
                    setData(oldData => [...oldData, newUser]);
                }}
            />
            <ModalUpdateUser
                show={showModalUpdate}
                onHide={() => setShowModalUpdate(false)}
                user={userToUpdate!}
                editUser={(updatedUser: IUser) => {
                    setData(oldData => oldData.map((u) => u.id === updatedUser.id ? updatedUser : u));
                }}
            />
            {isLoading ? (
                <Loader color="blue" size="150px" />
            ) : (
                <Table striped hover>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Email</th>
                            <th scope="col">Tipo</th>
                            <th className="text-center" scope="col">Editar</th>
                            <th className="text-center" scope="col">Bloquear</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map(user => (
                            <User
                                key={user.id}
                                user={user}
                                editUser={(updatedUser: IUser) => {
                                    setData(oldData => oldData.map((u) => u.id === updatedUser.id ? updatedUser : u));
                                }}
                                handleUpdateModal={user => handleUpdateModal(user)}
                            />
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
