import { Modal } from "react-bootstrap";
import { IBank, IScripts } from "../../interfaces/IBank";
import { FormEvent, useEffect, useState } from "react";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { FiFileText } from "react-icons/fi";

interface Props {
    bank: IBank | undefined;
    show: boolean;
    onHide: () => void;
    canEdit: boolean;
}

export const ModalScripts = ({ bank, show, onHide, canEdit }: Props) => {

    const [scripts, setScripts] = useState<IScripts[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [newScriptFile, setNewScriptFile] = useState<File | null>(null);
    const [newScriptName, setNewScriptName] = useState("");

    useEffect(() => {

        setIsLoading(true);
        if (show) {
            axios.get(`${LINKS.API}/banks/${bank?.id}/scripts`)
                .then(response => {
                    if (response.data.status === 200) {
                        setScripts(response.data.data);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => console.log(error))
                .finally(() => setIsLoading(false));
        }

    }, [bank, show, dispatch, navigate]);

    const submitNewScript = (e: FormEvent) => {
        e.preventDefault();

        if (!newScriptFile) {
            toast.error("Selecione um arquivo");
            return;
        }

        if (!newScriptName) {
            toast.error("Informe o nome do roteiro");
            return;
        }

        const formData = new FormData();
        formData.append("script", newScriptFile);
        formData.append("name", newScriptName);
        formData.append("bank_id", bank?.id.toString() || "");

        axios.post(`${LINKS.API}/banks/scripts`, formData)
            .then(response => {
                if (response.data.status === 201) {
                    setScripts([...scripts, response.data.data]);
                    setNewScriptName("");
                    setNewScriptFile(null);
                    toast.success(response.data.message);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => console.log(error));
    }

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Roteiros - {bank?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading
                        ?
                        <div className="w-100 d-flex align-items-center justify-content-center"><Loader size="150px" color="blue" /></div>
                        :
                        <div>
                            {scripts.map(script => (
                                <div key={script.id} className="d-flex align-items-center justify-content-between">
                                    <a href={LINKS.ARQUIVOS + script.script} target="_blank" rel="noreferrer" className="btn">
                                        <FiFileText size={20} />
                                        <span className="ms-2">{script.name}</span>
                                    </a>
                                </div>
                            ))}

                            {canEdit && <>
                                <hr />
                                <h4>Adicionar Roteiro</h4>
                                <div className="d-flex justify-content-end mt-3 gap-3">
                                    <form onSubmit={submitNewScript} className="w-100">
                                        <input
                                            className="form-control w-100 mb-2"
                                            type="file"
                                            name="script"
                                            id="script"
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    setNewScriptFile(e.target.files[0]);
                                                }
                                            }}
                                        />
                                        <input
                                            className="form-control w-100 mb-2"
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Nome do Roteiro"
                                            value={newScriptName}
                                            onChange={(e) => setNewScriptName(e.target.value)}
                                        />
                                        <button style={{ float: "right" }} type="submit" className="btn btn-primary">Adicionar</button>
                                    </form>
                                </div>

                            </>}
                        </div>
                }
            </Modal.Body>
        </Modal >
    )
}