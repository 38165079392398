import { useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { IBaseCustomer } from "../../interfaces/IBase";
import styles from "../../styles/Customers.module.scss";
import { Loader } from "../../components/Loader";
import classNames from "classnames";
import { logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { LINKS, formatCpf } from "../../config";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { FaEdit, FaEye } from "react-icons/fa";
import { ModalUpdateCustomer } from "../../components/Customers/ModalUpdateCustomer";

export const Customers = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState<IBaseCustomer[]>([]);
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(1);
    const [customersQuantity, setCustomersQuantity] = useState(0);
    const [tableFiltered, setTableFiltered] = useState<boolean>(false);

    const [showModalUpdateCustomer, setShowModalUpdateCustomer] = useState(false);
    const [customerSelected, setCustomerSelected] = useState<IBaseCustomer>({} as IBaseCustomer);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const fetchCustomers = (submit = false) => {

        const url = `${LINKS.API}/customers/${page - 1}`;

        const data = filter ? { filter: filter } : {};

        if (submit || page > 0) {
            axios.get(url, { params: data }).then((response) => {
                if (response.data.status === 200) {
                    setCustomers(response.data.data);
                    setCustomersQuantity(response.data.quantity);
                    if (submit && filter !== "") setTableFiltered(true);
                    else setTableFiltered(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
                setIsLoading(false);
            });
        }
    };
    useEffect(() => {
        dispatch(closeSidebar());
        fetchCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, dispatch]);

    // Calculate total pages
    const totalPages = Math.ceil(customersQuantity / 50); // Assuming each page has 10 items

    // Handlers for pagination
    const handleFirstPage = () => setPage(1);
    const handleLastPage = () => setPage(totalPages);
    const handleNextPage = () => setPage(prev => (prev < totalPages ? prev + 1 : prev));
    const handlePrevPage = () => setPage(prev => (prev > 1 ? prev - 1 : prev));

    return (
        <div className="container">

            <div className="d-flex align-items-center titulo gap-3">
                <h3>Clientes</h3>
            </div>
            <div className={classNames("gap-2", styles.filter)}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filtrar por nome ou cpf"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            fetchCustomers(true);
                        }
                    }}

                />
                <button
                    className="btn btn-primary"
                    onClick={() => fetchCustomers(true)}
                >
                    Filtrar
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => {
                        setFilter("");
                        fetchCustomers();
                    }}
                >
                    Limpar
                </button>
            </div>
            {
                isLoading
                    ?
                    <Loader color="blue" size="150px" />
                    :
                    <>

                        <Table className={styles.acessos} striped hover>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">CPF</th>
                                    <th scope="col">Telefone</th>
                                    <th scope="col">Idade</th>
                                    <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                        Editar
                                    </th>
                                    <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                        Detalhes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers.map(customer => (
                                    <tr key={customer.id}>
                                        <th scope="row">{customer.id}</th>
                                        <td>{customer.name}</td>
                                        <td>{formatCpf(customer.cpf)}</td>
                                        <td>{customer.phone}</td>
                                        <td>{customer.age}</td>
                                        <td className="text-center">
                                            <FaEdit
                                                cursor={"pointer"}
                                                color="black"
                                                onClick={() => {
                                                    setCustomerSelected(customer);
                                                    setShowModalUpdateCustomer(true);
                                                }}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <Link to={`/clientes/${customer.id}`}>
                                                <FaEye color="black" />
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <ModalUpdateCustomer
                            show={showModalUpdateCustomer}
                            onHide={() => setShowModalUpdateCustomer(false)}
                            customer={customerSelected}
                            onUpdate={(customer: IBaseCustomer) => {
                                setCustomers(customers.map(c => c.id === customer.id ? customer : c));
                            }}
                        />
                    </>




            }
            {/* Pagination Controls */}
            {
                !tableFiltered &&
                <Pagination className="d-flex justify-content-center">
                    <Pagination.First onClick={handleFirstPage} disabled={page === 1} />
                    <Pagination.Prev onClick={handlePrevPage} disabled={page === 1} />

                    {/* Show first page and ellipses if needed */}
                    {page > 3 && (
                        <>
                            <Pagination.Item onClick={() => setPage(1)}>1</Pagination.Item>
                            {page > 4 && <Pagination.Ellipsis />}
                        </>
                    )}

                    {/* Show three pages: the one before, the current, and the one after */}
                    {page > 2 && <Pagination.Item onClick={() => setPage(page - 1)}>{page - 1}</Pagination.Item>}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {page < totalPages && <Pagination.Item onClick={() => setPage(page + 1)}>{page + 1}</Pagination.Item>}

                    {/* Show last page and ellipses if needed */}
                    {page < totalPages - 2 && (
                        <>
                            {page < totalPages - 3 && <Pagination.Ellipsis />}
                            <Pagination.Item onClick={() => setPage(totalPages)}>{totalPages}</Pagination.Item>
                        </>
                    )}

                    <Pagination.Next onClick={handleNextPage} disabled={page === totalPages} />
                    <Pagination.Last onClick={handleLastPage} disabled={page === totalPages} />
                </Pagination>
            }

        </div>
    )
}