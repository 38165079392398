import { Form, Modal } from "react-bootstrap";
import { INotificationWithUser } from "../../pages/ManageNotifications/ManageNotifications";
import { useEffect, useState } from "react";
import { IUser } from "../../interfaces/IUser";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    notification: INotificationWithUser | null;
    onEdit: (updatedNotification: INotificationWithUser) => void;
}

export const ModalEditNotification = ({ notification, isOpen, onClose, onEdit }: Props) => {

    const [users, setUsers] = useState<IUser[]>([]);
    const [form, setForm] = useState<INotificationWithUser>({
        id: 0,
        user_id: 0,
        message: "",
        link: "",
        is_read: 0,
        read_at: "",
        created_at: "",
        user_name: ""
    });

    useEffect(() => {
        console.log(isOpen);
        if (isOpen && notification) {
            setForm(notification);
            axios
                .get(`${LINKS.API}/users`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setUsers(response.data.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [isOpen, notification]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!form.user_id || !form.message) {
            toast.error("Preencha todos os campos obrigatórios!");
            return;
        }

        axios.put(`${LINKS.API}/notifications/${notification?.id}`, form).then((response) => {
            if (response.data.status === 200) {
                onEdit(response.data.data);
                onClose();
            }
        }).catch((error) => { console.log(error); });
    };

    if (!notification) {
        return null;
    }

    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar notificação #{notification.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3">
                        <Form.Label>Usuário *</Form.Label>
                        <Form.Select name="user_id" value={form.user_id} onChange={(e) => setForm({ ...form, user_id: parseInt(e.target.value) })}>
                            <option value="">Selecione um usuário</option>
                            {users.map((user) => <option key={user.id} value={user.id}>{user.name} {user.last_name}</option>)}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Mensagem *</Form.Label>
                        <Form.Control name="message" value={form.message} onChange={(e) => setForm({ ...form, message: e.target.value })} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Link</Form.Label>
                        <Form.Control name="link" value={form.link ?? ""} onChange={(e) => setForm({ ...form, link: e.target.value })} />
                    </Form.Group>


                    <div style={{ float: "right" }}>
                        <button type="submit" className="btn btn-primary mt-3">Salvar</button>
                        <button type="button" className="btn btn-secondary mt-3 ms-2" onClick={onClose}>Cancelar</button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};