import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { INotification } from "../../interfaces/INotification";
import { Table } from "react-bootstrap";
import axios from "axios";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import styles from "../../styles/Notifications.module.scss";
import { IoCloseSharp } from "react-icons/io5";
import { BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Loader } from "../../components/Loader";
import { ModalEditNotification } from "../../components/ManageNotifications/ModalEditNotification";
import { ModalDeleteNotification } from "../../components/ManageNotifications/ModalDeleteNotification";
import { ModalNewNotification } from "../../components/ManageNotifications/ModalNewNotification";

export type INotificationWithUser = INotification & { user_name: string };

export const ManageNotifications = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState<INotificationWithUser[]>([]);

    const [isOpenEditNotificationModal, setIsOpenEditNotificationModal] = useState(false);
    const [notificationToEdit, setNotificationToEdit] = useState<INotificationWithUser | null>(null);

    const [isOpenDeleteNotificationModal, setIsOpenDeleteNotificationModal] = useState(false);
    const [notificationToDelete, setNotificationToDelete] = useState<INotification | null>(null);

    const [isOpenNewNotificationModal, setIsOpenNewNotificationModal] = useState(false);

    useEffect(() => {
        dispatch(closeSidebar());
        setIsLoading(true);
        axios.get(`${LINKS.API}/notifications`).then((response) => {
            if (response.data.status === 200) {
                setNotifications(response.data.data);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
            setIsLoading(false);
        }).catch((error) => { console.log(error); setIsLoading(false); });
    }, [dispatch, navigate]);


    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Gerenciar Notificações</h3>
                <FiPlusCircle
                    size={21}
                    title="Nova Notificação"
                    color="black"
                    cursor={"pointer"}
                    style={{ marginBottom: "10px" }}
                    onClick={() => setIsOpenNewNotificationModal(true)}
                />
            </div>
            {
                notifications.length > 0 ? (
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Usuário</th>
                                <th>Mensagem</th>
                                <th>Link</th>
                                <th className="text-center">Criada em</th>
                                <th className="text-center">Lida em</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                notifications.map((notification, index) => (
                                    <tr style={{ verticalAlign: "middle" }} key={index}>
                                        <td>{notification.id}</td>
                                        <td>{notification.user_name}</td>
                                        <td title={notification.message} className={styles.message}>{notification.message}</td>
                                        <td title={notification.link ?? ""} className={styles.link}>
                                            <a href={notification.link ?? ""} target="_BLANK" rel="noreferrer">{notification.link}</a>
                                        </td>
                                        <td className="text-center">{notification.created_at}</td>
                                        <td className="text-center">
                                            {notification.is_read === 1 ? notification.read_at : <IoCloseSharp color="red" title="Não lida" />}
                                        </td>
                                        <td className="text-center d-flex gap-2">
                                            <button
                                                className="btn btn-outline-secondary"
                                                onClick={() => {
                                                    setNotificationToEdit(notification);
                                                    setIsOpenEditNotificationModal(true);
                                                }}
                                            >
                                                <FaEdit size={15} />
                                            </button>
                                            <button
                                                className="btn btn-outline-danger"
                                                onClick={() => {
                                                    setNotificationToDelete(notification);
                                                    setIsOpenDeleteNotificationModal(true);
                                                }}
                                            >
                                                <BsTrash size={15} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                ) : (
                    isLoading ? <Loader size="150px" color="blue" /> : <p className="date">Não existem notificações ativas.</p>
                )
            }
            <ModalEditNotification
                isOpen={isOpenEditNotificationModal}
                onClose={() => setIsOpenEditNotificationModal(false)}
                notification={notificationToEdit!}
                onEdit={(updatedNotification) => {
                    setNotifications((oldNotifications) => oldNotifications.map((notification) => {
                        if (notification.id === updatedNotification.id) {
                            return updatedNotification;
                        }
                        return notification;
                    }));
                }}
            />
            <ModalDeleteNotification
                isOpen={isOpenDeleteNotificationModal}
                onClose={() => setIsOpenDeleteNotificationModal(false)}
                notification={notificationToDelete!}
                onDelete={(deletedNotification) => {
                    setNotifications((oldNotifications) => oldNotifications.filter((notification) => notification.id !== deletedNotification.id));
                }}
            />
            <ModalNewNotification
                show={isOpenNewNotificationModal}
                onHide={() => setIsOpenNewNotificationModal(false)}
                handleNewNotification={(notification) => setNotifications((oldNotifications) => [notification, ...oldNotifications])}
            />
        </div>
    )
};