import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { logOut } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { IBank } from "../../interfaces/IBank";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    show: boolean;
    onHide: () => void;
    onEdit: (bank: IBank) => void;
    bank: IBank;
}
interface IFormData {
    nome: string;
    link: string;
    foto: File | null;
    visivelSite: boolean;
    visivelAdmin: boolean;
    id: number | null;
}

export const ModalUpdateBank = ({ show, onHide, bank, onEdit }: Props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<IFormData>({
        nome: '',
        link: '',
        foto: null,
        visivelSite: false,
        visivelAdmin: false,
        id: null
    });

    useEffect(() => {
        if (bank) {
            setFormData({
                nome: bank.name,
                link: bank.link,
                foto: bank.photo ? null : null,
                visivelSite: bank.visible_public === 1 ? true : false,
                visivelAdmin: bank.visible_admin === 1 ? true : false,
                id: bank.id
            });
        }
    }, [bank]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const formDataObj = new FormData();
        formDataObj.append('name', formData.nome);
        formDataObj.append('link', formData.link);
        formDataObj.append('visible_public', (formData.visivelSite ? 1 : 0).toString());
        formDataObj.append('visible_admin', (formData.visivelAdmin ? 1 : 0).toString());
        formDataObj.append('id', formData.id?.toString() || '');
        if (formData.foto) formDataObj.append('photo', formData.foto);

        try {
            const response = await axios.post(`${LINKS.API}/banks/update`, formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data.status === 200) {
                toast.success(response.data.message);
                onEdit(response.data.data);
                setFormData({
                    foto: response.data.data.photo,
                    link: response.data.data.link,
                    id: bank.id,
                    nome: response.data.data.name,
                    visivelAdmin: response.data.data.visible_admin,
                    visivelSite: response.data.data.visible_public
                });
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Erro ao editar banco');
            console.error(error);
        }
    }

    if (!bank) return null;

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Banco</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group controlId="banco_nome">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome do banco"
                            name="nome"
                            value={formData.nome}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="banco_foto">
                        <Form.Label>Foto</Form.Label>
                        <input
                            type="file"
                            className="form-control"
                            name="foto"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        foto: e.target.files ? e.target.files[0] : null
                                    }));
                                }
                            }}
                        />
                        {bank.photo && <a rel="noreferrer" href={`${LINKS.ARQUIVOS}${bank.photo}`} target="_BLANK">FOTO ATUAL</a>}
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="banco_link">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o link do banco"
                            name="link"
                            value={formData.link}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="banco_visiveladmin">
                        <div className="d-flex gap-2">
                            <Form.Label>Visível no admin</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="visivelAdmin"
                                checked={formData.visivelAdmin}
                                onChange={() => setFormData((prevData) => ({ ...prevData, visivelAdmin: !prevData.visivelAdmin }))}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="banco_visivelsite">
                        <div className="d-flex gap-2">
                            <Form.Label>Visível no site (Público)</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="visivelSite"
                                checked={formData.visivelSite}
                                onChange={() => setFormData((prevData) => ({ ...prevData, visivelSite: !prevData.visivelSite }))}
                            />
                        </div>
                    </Form.Group>
                    <Modal.Footer className="mt-2">
                        <Button type="submit" variant="primary">Atualizar</Button>
                        <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}