import { LINKS } from "../../config";
import { IBank } from "../../interfaces/IBank";
import styles from '../../styles/Banks.module.scss';
import { useState } from "react";
import { FiFileText } from "react-icons/fi";
import { Button } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import { ModalImportantPhones } from "./ModalImportantPhones";
import { ModalScripts } from "./ModalScripts";

interface Props {
    bank: IBank;
}

export const Bank = ({ bank }: Props) => {

    const [showModalImportantPhones, setShowModalImportantPhones] = useState(false);
    const [showModalScripts, setShowModalScripts] = useState(false);

    return (
        <div className={styles.div_bank}>
            <div className={styles.banco}>
                <a className={styles.banco} href={bank.link} target="_blank" rel="noreferrer">
                    <img src={`${LINKS.ARQUIVOS}${bank.photo}`} className={styles.img_banco} alt={`Imagem do banco ${bank.name}`} />
                </a>
                <div className={styles.btn_editDelete}>
                    <div className="d-flex gap-2">
                        <Button title="Telefone Importantes" variant="outline-dark" onClick={() => { setShowModalImportantPhones(true) }}>
                            <FaPhone />
                        </Button>
                        <Button title="Roteiros" variant="outline-dark" onClick={() => { setShowModalScripts(true) }}>
                            <FiFileText />
                        </Button>
                    </div>
                </div>
            </div>

            <ModalImportantPhones
                bank={bank}
                onHide={() => setShowModalImportantPhones(false)}
                show={showModalImportantPhones}
                canEdit={false}
            />
            <ModalScripts
                bank={bank}
                onHide={() => setShowModalScripts(false)}
                show={showModalScripts}
                canEdit={false}
            />
        </div>
    );
}
