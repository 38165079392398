import { useState } from "react";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { IAcesso } from "../../interfaces/IAcesso";
import { FaLock, FaRegEyeSlash, FaTrash, FaUnlock } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import styles from "../../styles/Acessos.module.scss";
import { FaEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ModalEditarAcesso } from "./ModalEditarAcesso";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    acesso: IAcesso;
    deleteAcesso: () => void;
    handleEdit: (updatedAcesso: IAcesso) => void;
}

export const Acesso = ({ acesso, deleteAcesso, handleEdit }: Props) => {
    const [showPass, setShowPass] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const currentUser = useSelector(selectCurrentUser);
    const handleEditClick = () => {
        setShowModal(true);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleToggleActive = (id: number) => {
        const confirmDelete = window.confirm(`Deseja realmente ${acesso.active === 1 ? "des" : ""}ativar este acesso?`);
        if (confirmDelete) {
            axios.post(`${LINKS.API}/access/toggle/${id}`).then(response => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    handleEdit(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                }
                else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error(error);
                toast.error("Erro ao ativar/desativar acesso");
            });
        }
    };

    const handleDelete = (id: number) => {
        const confirmDelete = window.confirm("Deseja realmente excluir este acesso?");
        if (confirmDelete) {
            axios.delete(`${LINKS.API}/access/${id}`).then(response => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    deleteAcesso();

                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                }
                else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error(error);
                toast.error("Erro ao excluir acesso");
            });
        }
    };
    return (
        <tr className={acesso.active === 0 ? styles.inactive_access : ""}>
            <td>{acesso.id}</td>

            <td title={`${acesso.name?.trim()} ${acesso.last_name?.trim()}`} className={styles.ellipsedText}>{`${acesso.name?.trim()} ${acesso.last_name ? `${acesso.last_name.substring(0, 1)}.` : ''}`}</td>


            <td className={styles.ellipsedText}>
                <a rel="noreferrer" href={acesso.link} target="_BLANK">
                    {acesso.link}
                </a>
            </td>
            <td title={acesso.banco} className={`${styles.ellipsedText}`}>{acesso.banco}</td>
            <td title={acesso.promotora} className={`${styles.ellipsedText}`}>{acesso.promotora}</td>
            <td title={acesso.login} className={styles.ellipsedText}>{acesso.login}</td>
            <td className={styles.password_div}>
                {
                    (acesso.robo === 1 && currentUser.type !== "admin")
                        ? '*'.repeat(10)
                        : (
                            <>
                                {
                                    showPass
                                        ? acesso.password
                                        : '*'.repeat(10)
                                }
                                {
                                    showPass
                                        ? <FaRegEye className={styles.showPass} onClick={() => setShowPass(false)} />
                                        : <FaRegEyeSlash className={styles.showPass} onClick={() => setShowPass(true)} />
                                }
                            </>
                        )
                }
            </td>
            {
                (currentUser.type === "admin" || acesso.conta_id === currentUser.id) ?
                    <>
                        <td title="Editar Acesso" className="text-center">
                            <FaEdit size={20} color="black" cursor="pointer" onClick={handleEditClick} />
                        </td>
                        <ModalEditarAcesso handleEdit={(updatedAcesso: IAcesso) => handleEdit(updatedAcesso)} show={showModal} onHide={() => setShowModal(false)} acesso={acesso} />
                    </>
                    : <td></td>
            }
            {
                currentUser.type === "admin" &&
                <td title="Excluir Acesso" className="text-center">
                    <FaTrash size={20} color="black" cursor="pointer" onClick={() => handleDelete(acesso.id)} />
                </td>
            }
            {
                currentUser.type === "admin" && acesso.active === 0 &&
                (
                    <td title="Ativar Acesso" className="text-center">
                        <FaUnlock size={20} color="black" cursor="pointer" onClick={() => handleToggleActive(acesso.id)} />
                    </td>
                )
            }
            {
                currentUser.type === "admin" && acesso.active === 1 &&
                (
                    <td title="Desativar Acesso" className="text-center">
                        <FaLock size={20} color="black" cursor="pointer" onClick={() => handleToggleActive(acesso.id)} />
                    </td>
                )
            }
        </tr>
    );
}
