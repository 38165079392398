import { BsEye } from "react-icons/bs";
import classNames from "classnames";
import { Divider } from "@adobe/react-spectrum";
import { Button } from "react-bootstrap";
import { MdMoneyOff } from "react-icons/md";
// import { FiFileText } from "react-icons/fi";
import styles from "../../styles/Bases.module.scss";
import { IBase, IBaseCustomer } from "../../interfaces/IBase";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { FaCheck, FaEdit, FaPhone, FaUser } from "react-icons/fa";

interface Props {
    customer: IBaseCustomer;
    setOpenDetailId: (id: number | null) => void;
    isOpenDetails: boolean;
    base: IBase;
    hideModal: () => void;
    setLocalCustomer: (customer: IBaseCustomer) => void;
    handleOpenDetails: () => void;
    handlePhoneInputMode: () => void;
    inputMode: "text" | "input";
}

export const BaseCustomerDetails = ({
    customer, base, setLocalCustomer, hideModal, setOpenDetailId, handleOpenDetails, isOpenDetails, handlePhoneInputMode
}: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const formatSellerName = (seller: string) => {
        if (!seller) return "";
        const [seller_name, seller_lastname] = seller.split(" ");
        return `${seller_name} ${seller_lastname[0]}.`;
    };

    const handleFinishEvaluation = async () => {
        const response = await axios.put(`${LINKS.API}/bases/evaluation/finish`, {
            customer_id: customer.id,
            base_id: base.id
        });

        if (response.data.status === 200) {
            toast.success(response.data.message);
            setLocalCustomer({
                ...customer,
                evaluated_at: response.data.data.evaluated_at,
                evaluated_by: response.data.data.evaluated_by
            }); // Update local customer state
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);
        } else {
            toast.error(response.data.message);
        }
    };

    const handleUpdateDetails = (field: string, value: number) => {
        axios.put(`${LINKS.API}/bases/evaluation/details`, {
            customer_id: customer.id,
            base_id: base.id,
            field: field,
            value: value
        }).then(response => {

            if (response.data.status === 200) {
                setLocalCustomer({
                    ...customer,
                    [field]: value,
                    // evaluated_at: response.data.data.evaluated_at,
                    // evaluated_by: response.data.data.evaluated_by
                }); // Update local customer state
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        });

    }

    const handleNavigateToProfile = () => {
        navigate(`/clientes/${customer.id}`);
        hideModal();
        setOpenDetailId(null);
    }

    const handleEditPhone = () => {
        handleOpenDetails();
        handlePhoneInputMode();
    };

    return <td
        className={classNames("text-center", styles.details_button)}

    >
        <div

            style={{ width: "100%", height: "100%" }}>
            <BsEye
                onClick={handleOpenDetails}
                cursor={"pointer"}
                color={customer.evaluated_at ? "green" : "black"}
            />
        </div>
        <div className={classNames(styles.open_details, isOpenDetails && styles.visible)}>
            <div className="d-flex align-items-center justify-content-around mt-2 w-100">
                <h6>{customer.name}</h6>
                <IoCloseSharp
                    cursor={"pointer"}
                    onClick={handleOpenDetails}
                />
            </div>
            <Divider />
            {
                customer.seller && <p>Vendedor: {formatSellerName(customer.seller)}</p>
            }
            <h6 className="m-0">Avaliação</h6>
            <div className={styles.actions}>
                <div>
                    <Button
                        title="Vendedor entrou em contato?"
                        variant={customer.contacted === 1 ? "secondary" : "outline-secondary"}
                        disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                        onClick={() => {
                            if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                handleUpdateDetails("contacted", customer.contacted ? 0 : 1)
                            }
                        }}
                    >
                        <FaPhone />
                    </Button>
                </div>
                <div>
                    <Button
                        title="Cliente perdeu margem?"
                        variant={customer.lose_margin === 1 ? "danger" : "outline-danger"}
                        disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                        onClick={() => {
                            if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                handleUpdateDetails("lose_margin", customer.lose_margin ? 0 : 1)
                            }
                        }}
                    >
                        <MdMoneyOff />
                    </Button>
                </div>
                <div>
                    <Button
                        title="Ver perfil"
                        variant="outline-primary"
                        disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                        onClick={() => {
                            if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                handleNavigateToProfile();
                            }
                        }}
                    >
                        <FaUser />
                    </Button>
                </div>
                <div>
                    <Button
                        title="Finalizar avaliação"
                        variant={customer.evaluated_at ? "success" : "outline-success"}
                        disabled={currentUser.type !== "admin" && currentUser.type !== "operacional"}
                        onClick={() => {
                            if (currentUser.type === "admin" || currentUser.type === "operacional") {
                                handleFinishEvaluation();
                            }
                        }}
                    >
                        <FaCheck />
                    </Button>
                </div>
            </div>

            <h6 className="m-0 mt-1">Ações</h6>
            <div className={styles.actions}>
                <Button
                    title="Editar telefone"
                    variant="outline-primary"
                    onClick={handleEditPhone}
                >
                    <FaEdit />
                </Button>
                {/* <Button
                    title="Anotar observações"
                    variant="outline-primary"
                    onClick={handleNavigateToProfile}
                >
                    <FiFileText />
                </Button> */}
            </div>
            {
                customer.evaluated_at && (
                    <span className={styles.evaluated}>
                        Avaliado por {formatSellerName(customer.evaluated_by)} em {customer.evaluated_at}
                    </span>
                )
            }

        </div>
    </td>
}