import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentUser, logOut } from "../../features/auth/authSlice";
import { Acesso } from "../../components/Acessos/Acesso";
import { IAcesso } from "../../interfaces/IAcesso";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import styles from "../../styles/Acessos.module.scss";
import { ModalNovoAcesso } from "../../components/Acessos/ModalNovoAcesso";
import { Loader } from "../../components/Loader";
import { IUser } from "../../interfaces/IUser";

export const Acessos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<IAcesso[]>([]);
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<IAcesso[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);
  const [filterUserId, setFilterUserId] = useState<number>(0);
  const [filterBank, setFilterBank] = useState<string>("");
  const [filterPromoter, setFilterPromoter] = useState<string>("");
  const [filterLogin, setFilterLogin] = useState<string>("");

  useEffect(() => {
    setFilteredData(data.filter((acesso) => {
      if (filterUserId !== 0 && acesso.conta_id !== filterUserId) {
        return false;
      }
      if (filterBank !== "" && !acesso.banco.toLowerCase().includes(filterBank.toLowerCase())) {
        return false;
      }
      if (filterPromoter !== "" && !acesso.promotora.toLowerCase().includes(filterPromoter.toLowerCase())) {
        return false;
      }
      if (filterLogin !== "" && !acesso.login.toLowerCase().includes(filterLogin.toLowerCase())) {
        return false;
      }
      return true;
    }));
  }, [data, filterUserId, filterBank, filterPromoter, filterLogin]);
  useEffect(() => {

    dispatch(closeSidebar());

    const getUsers = async () => {
      axios.get(`${LINKS.API}/users`).then((response) => {
        if (response.data.status === 200) {
          setUsers(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
    };

    const fetchData = async () => {
      try {
        const response = await axios.get(`${LINKS.API}/access`);
        if (response.data.status === 200) {
          setData(response.data.data);
          setFilteredData(response.data.data);
          setIsLoading(false);
        } else if (response.data.status === 401) {
          const redirectTo = window.location.pathname;
          dispatch(logOut());
          navigate('/login', { state: { from: redirectTo } });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Erro ao carregar acessos");
      }
    };
    getUsers();
    fetchData();
  }, [dispatch, navigate, currentUser]);

  const handleModalClose = () => setShowModal(false);
  const handleDeleteAcesso = (id: number) => {
    setFilteredData(oldData => oldData.filter(data => data.id !== id));
    setData(oldData => oldData.filter(data => data.id !== id));
  }
  const handleEditAcesso = (updatedAcesso: IAcesso) => {
    setFilteredData(oldData => oldData.map(acesso => {
      if (acesso.id === updatedAcesso.id) {
        return {
          ...acesso,
          ...updatedAcesso
        };
      }
      return acesso;
    }));
    setData(oldData => oldData.map(acesso => {
      if (acesso.id === updatedAcesso.id) {
        return {
          ...acesso,
          ...updatedAcesso
        };
      }
      return acesso;
    }));
  };
  const handleNovoAcesso = (newAcess: IAcesso) => {
    setFilteredData(oldData => [newAcess, ...oldData]);
    setData(oldData => [newAcess, ...oldData]);
  }
  return (
    <div className="container">

      <div className="d-flex align-items-center titulo gap-3">
        <h3>Acessos</h3>
        <FiPlusCircle
          size={21}
          title="Novo Acesso"
          color="black"
          style={{ marginBottom: "10px" }}
          onClick={() => setShowModal(true)}
          cursor="pointer"
        />
      </div>
      <ModalNovoAcesso handleNovoAcesso={(newAccess: IAcesso) => handleNovoAcesso(newAccess)} show={showModal} onHide={handleModalClose} />
      <div className="w-100 mb-3 d-flex gap-1">
        <select
          className="form-select form-control"
          onChange={(e) => {
            setFilterUserId(parseInt(e.target.value));
          }}
        >
          <option value={0}>Filtrar por usuário</option>
          {
            users.map(user => (
              <option key={user.id} value={user.id}>{user.name} {user.last_name[0]}.</option>
            ))
          }
        </select>
        <input
          type="text"
          className="form-control"
          placeholder="Filtrar por banco"
          value={filterBank}
          onChange={(e) => setFilterBank(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Filtrar por promotora"
          value={filterPromoter}
          onChange={(e) => setFilterPromoter(e.target.value)}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Filtrar por login"
          value={filterLogin}
          onChange={(e) => setFilterLogin(e.target.value)}
        />
      </div>

      {
        isLoading
          ?
          <Loader color="blue" size="150px" />
          :
          <>
            <Table className={styles.acessos} striped hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Usuário</th>
                  <th scope="col">Link</th>
                  <th scope="col">Banco</th>
                  <th scope="col">Promotora</th>
                  <th scope="col">Login</th>
                  <th scope="col">Senha</th>
                  <th className="text-center" scope="col">

                  </th>
                  {
                    currentUser.type === "admin" &&
                    <th className="text-center" scope="col">

                    </th>
                  }
                  {
                    currentUser.type === "admin" &&
                    <th scope="col"> </th>
                  }
                </tr>
              </thead>
              <tbody>
                {filteredData.map(acesso => (
                  <Acesso
                    key={acesso.id}
                    acesso={acesso}
                    deleteAcesso={() => handleDeleteAcesso(acesso.id)}
                    handleEdit={(updatedAcesso: IAcesso) => handleEditAcesso(updatedAcesso)}
                  />
                ))}
              </tbody>
            </Table>
          </>
      }
    </div >
  );
};

