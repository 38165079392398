import { Modal, Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IUser } from "../../interfaces/IUser";

interface Props {
    show: boolean;
    onHide: () => void;
    setNewUser: (user: IUser) => void;
}

export const ModalNewUser = ({ show, onHide, setNewUser }: Props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [photo, setPhoto] = useState<File | null>(null);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", usuario.name);
        formData.append("last_name", usuario.last_name);
        formData.append("email", usuario.email);
        formData.append("password", usuario.password);
        formData.append("type", usuario.type);
        if (photo) formData.append("photo", photo);

        if (usuario.password !== usuario.confirm_password) {
            toast.error("As senhas não coincidem");
            return;
        }

        for (let key in requiredFields) {
            if (requiredFields[key as keyof typeof requiredFields] && !usuario[key as keyof typeof usuario]) {
                toast.error("Preencha todos os campos obrigatórios");
                return;
            }
        }

        try {
            const response = await axios.post(`${LINKS.API}/users`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            if (response.data.status === 201) {
                toast.success("Usuário criado com sucesso");
                setNewUser(response.data.data);
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
            setIsLoading(false);
        } catch (error) {
            toast.error("Erro ao criar usuário");
            console.error(error);
            setIsLoading(false);
        }
    }
    const requiredFields = {
        name: true,
        last_name: true,
        email: true,
        photo: false,
        password: true,
        confirm_password: true,
        type: true
    }
    const [usuario, setUsuario] = useState({
        name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        type: "vendedor"
    });

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Novo Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group className="mt-3" controlId="name">
                        <Form.Label>Nome *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome"
                            name="name"
                            value={usuario.name}
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    name: e.target.value,
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="last_name">
                        <Form.Label>Sobrenome *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o sobrenome"
                            name="last_name"
                            value={usuario.last_name}
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    last_name: e.target.value
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="photo">
                        <Form.Label>Foto</Form.Label>{ }
                        <input
                            type="file"
                            className="form-control"
                            id="photo"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setPhoto(e.target.files[0]);
                                }
                            }}
                        />

                    </Form.Group>
                    <Form.Group className="mt-3" controlId="email">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Digite o email"
                            name="email"
                            value={usuario.email}
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    email: e.target.value
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="password">
                        <Form.Label>Senha *</Form.Label>
                        <Form.Control
                            type={showPass ? "text" : "password"}
                            placeholder="Digite a senha"
                            name="password"
                            value={usuario.password}
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    password: e.target.value
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="confirm_password">
                        <Form.Label>Confirmar Senha *</Form.Label>
                        <Form.Control
                            type={showConfirmPass ? "text" : "password"}
                            placeholder="Digite a senha novamente"
                            name="confirm_password"
                            value={usuario.confirm_password}
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    confirm_password: e.target.value
                                });
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="showPass">
                        <Form.Check
                            type="checkbox"
                            label="Mostrar senhas"
                            onChange={() => {
                                setShowPass(!showPass);
                                setShowConfirmPass(!showConfirmPass);
                            }}
                        />
                    </Form.Group>
                    {/* SELECT COM O TIPO (admin, operacional, vendedor) */}
                    <Form.Group className="mt-3" controlId="type">
                        <Form.Label>Tipo *</Form.Label>
                        <Form.Control
                            as="select"
                            name="type"
                            onChange={(e) => {
                                setUsuario({
                                    ...usuario,
                                    type: e.target.value
                                });
                            }}
                            value={usuario.type}
                        >
                            <option value="admin">Admin</option>
                            <option value="operacional">Operacional</option>
                            <option value="vendedor">Vendedor</option>
                        </Form.Control>
                    </Form.Group>
                    <Button
                        className="mt-3"
                        variant="primary"
                        type="submit"
                        disabled={isLoading}
                    >
                        {isLoading ? "Carregando..." : "Criar Usuário"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

