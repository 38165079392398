import { Modal, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import styles from "../../styles/Bases.module.scss";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { IBase, IBaseCustomer } from "../../interfaces/IBase";
import { BaseRow } from "./BaseRow";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase;
}

export const ViewBase = ({ onHide, isOpen, base }: Props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<IBaseCustomer[]>([]);
    const token = useSelector(selectCurrentToken);
    const [openDetailId, setOpenDetailId] = useState<number | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        if (!isOpen) return;

        setIsLoading(true);

        const response = axios.get(`${LINKS.API}/bases/view/${base.id}`);

        response.then(response => {
            if (response.data.status === 200) {
                setData(response.data.data);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        });

    }, [isOpen, base, dispatch, navigate, token]);



    const handleHide = () => {
        onHide();
        setData([]);
    };

    return (
        <Modal show={isOpen} onHide={handleHide} centered dialogClassName="modal_xxl">
            <Modal.Header closeButton >
                <div className="d-flex flex-column">
                    <Modal.Title>
                        <Link to={`/bases/${base.id}`} style={{ color: "black", textDecoration: "none" }}>
                            {base.name}
                        </Link>
                    </Modal.Title>
                    <p className={styles.date}>Última checagem: {
                        base.last_check_at
                    }</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? <div className="d-flex align-items-center w-100 justify-content-center"><Loader size="150px" color="blue" /></div> :

                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>CPF</th>
                                    <th className="text-center">Idade</th>
                                    <th>Telefone</th>
                                    <th style={{ textAlign: "right" }}>Margem</th>
                                    <th style={{ textAlign: "right" }}>Cartão</th>
                                    <th className="text-center">Fechou</th>
                                    <th className="text-center">Perdeu <br /> margem</th>
                                    <th className="text-center">Detalhes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((customer, index) =>
                                        <BaseRow
                                            customer={customer}
                                            key={index}
                                            isOpenDetails={openDetailId === customer.id}
                                            setOpenDetailId={setOpenDetailId}
                                            base={base}
                                            hideModal={handleHide}
                                        />)
                                }
                            </tbody>
                        </Table>
                }
            </Modal.Body>
        </Modal>
    );
};
