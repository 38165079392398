import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { Loader } from "../../components/Loader";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { FiFileText, FiPlusCircle } from "react-icons/fi";
import { IBank } from "../../interfaces/IBank";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Button } from "react-bootstrap";
import { MdPhone } from "react-icons/md";
import { ModalUpdateBank } from "../../components/Banks/ModalUpdateBank";
import { ModalDeleteBank } from "../../components/Banks/ModalDeleteBank";
import { ModalScripts } from "../../components/Banks/ModalScripts";
import { ModalImportantPhones } from "../../components/Banks/ModalImportantPhones";


export const ManageBanks = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [banks, setBanks] = useState<IBank[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedBank, setSelectedBank] = useState<IBank>();

    const [showModalUpdateBank, setShowModalUpdateBank] = useState(false);
    const [showModalDeleteBank, setShowModalDeleteBank] = useState(false);
    const [showModalScripts, setShowModalScripts] = useState(false);
    const [showModalImportantPhones, setShowModalImportantPhones] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(closeSidebar());

        axios.get(`${LINKS.API}/banks/all`).then(response => {
            if (response.data.status === 200) {
                setBanks(response.data.data);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => {
            console.error("Error fetching tutorials:", error);
            toast.error("Error fetching tutorials. Please try again later.");
        });
    }, [currentUser.token, dispatch, navigate]);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Gerenciar Bancos</h3>
                <Link to="/bancos/novo">
                    <FiPlusCircle
                        size={21}
                        title="Novo Banco"
                        color="black"
                        style={{ marginBottom: "10px" }}
                    />
                </Link>
            </div>
            <div>
                {
                    isLoading ? <Loader color="blue" size="150px" /> :

                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                            {
                                banks.map(bank => (
                                    <div key={bank.id} className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <img style={{ width: "50px", borderRadius: "50%" }} src={`${LINKS.ARQUIVOS}${bank.photo}`} alt={`Foto do ${bank.name}`} />
                                                <h5 className="card-title mt-2">{bank.name}</h5>
                                                <div className="d-flex justify-content-center flex-column">
                                                    <div>
                                                        {
                                                            bank.visible_admin ? <FaCheck color="green" /> : <IoClose color="red" />
                                                        } Vísivel no Admin

                                                    </div>
                                                    <div>
                                                        {
                                                            bank.visible_public ? <FaCheck color="green" /> : <IoClose color="red" />
                                                        } Vísivel no Público

                                                    </div>

                                                    <div className="mt-3 d-flex flex-column gap-1">
                                                        <Button
                                                            variant="outline-dark"
                                                            className="d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => { setSelectedBank(bank); setShowModalImportantPhones(true); }}
                                                        >
                                                            <MdPhone />
                                                            Telefones
                                                        </Button>
                                                        <Button
                                                            variant="outline-dark"
                                                            className="d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => { setSelectedBank(bank); setShowModalScripts(true); }}
                                                        >
                                                            <FiFileText />
                                                            Roteiros
                                                        </Button>
                                                        <Button
                                                            variant="outline-primary"
                                                            className="d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => { setSelectedBank(bank); setShowModalUpdateBank(true); }}
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="outline-danger"
                                                            className="d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => { setSelectedBank(bank); setShowModalDeleteBank(true); }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
            <ModalUpdateBank
                bank={selectedBank!}
                onEdit={(bank: IBank) => {
                    setBanks(banks.map(b => b.id === Number(bank.id) ? bank : b));
                }}
                show={showModalUpdateBank}
                onHide={() => setShowModalUpdateBank(false)}
            />
            <ModalDeleteBank
                bank={selectedBank!}
                show={showModalDeleteBank}
                onHide={() => setShowModalDeleteBank(false)}
                handleDeleteBank={(bank: IBank) => {
                    setBanks(banks.filter(b => b.id !== bank.id));
                }}
            />
            <ModalScripts
                show={showModalScripts}
                onHide={() => setShowModalScripts(false)}
                bank={selectedBank!}
                canEdit={true}
            />
            <ModalImportantPhones
                show={showModalImportantPhones}
                onHide={() => setShowModalImportantPhones(false)}
                bank={selectedBank!}
                canEdit={true}
            />
        </div>
    );
};