import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { formatCpf, LINKS } from "../../config";
import axios from "axios";
import { logOut } from "../../features/auth/authSlice";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    user: IUser | undefined;
    onViewMode: () => void;
}

export const ModalUpdateMoreInfo = ({ isOpen, onClose, user, onViewMode }: Props) => {
    const unformatDate = (date: string) => {
        try {
            const [day, month, year] = date.split('/');
            return `${year}-${month}-${day}`;
        } catch (error) {
            return '';
        }
    }
    const [formData, setFormData] = useState({
        admission_date: unformatDate(user?.admission_date || ''),
        admitted_company: user?.admitted_company || '',
        cpf: formatCpf(user?.cpf || ''),
        birth_date: unformatDate(user?.birth_date || ''),
        phone_personal: user?.phone_personal || '',
        phone_store: user?.phone_store || '',
        mother_name: user?.mother_name || '',
        father_name: user?.father_name || '',
        rg: user?.rg || '',
        expedition_date: unformatDate(user?.expedition_date || ''),
        bank: user?.bank || '',
        agency: user?.agency || '',
        account: user?.account || '',
        account_type: user?.account_type || '',
        pix: user?.pix || '',
        certifications: user?.certifications?.map(cert => ({ ...cert, insert: false, certification_date: unformatDate(cert.certification_date) })) || [],
        emails: user?.emails?.map(email => ({ ...email, insert: false })) || [],
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCertificationChange = (index: number, field: string, value: string) => {
        const updatedCertifications = [...formData.certifications];
        updatedCertifications[index] = {
            ...updatedCertifications[index],
            [field]: value,
        };
        setFormData({
            ...formData,
            certifications: updatedCertifications,
        });
    };

    const handleEmailChange = (index: number, value: string) => {
        const updatedEmails = [...formData.emails];
        updatedEmails[index] = {
            ...updatedEmails[index],
            email: value,
        };
        setFormData({
            ...formData,
            emails: updatedEmails,
        });
    };

    const deleteCertificationFromBackend = async (id: number) => {
        try {
            const response = await axios.delete(`${LINKS.API}/profile/certification/${id}`);
            if (response.data.status === 200) {
                toast.success("Certificação deletada com sucesso.");
                console.log(`Certificação com ID ${id} deletada com sucesso.`);
            } else {
                console.log(`Erro ao deletar certificação com ID ${id}: `, response.data);
                toast.error("Erro ao deletar certificação.");
            }
        } catch (error) {
            console.error(`Erro ao deletar certificação com ID ${id}:`, error);
        }
    };

    const deleteEmailFromBackend = async (id: number) => {
        try {
            const response = await axios.delete(`${LINKS.API}/profile/email/${id}`);
            if (response.data.status === 200) {
                toast.success("Email deletado com sucesso.");
                console.log(`Email com ID ${id} deletado com sucesso.`);
            } else {
                console.log(`Erro ao deletar email com ID ${id}: `, response.data);
                toast.error("Erro ao deletar email.");
            }
        } catch (error) {
            console.error(`Erro ao deletar email com ID ${id}:`, error);
        }
    };

    const removeCertification = (id: number) => {
        const certification = formData.certifications.find(cert => cert.id === id);
        if (certification && !certification.insert) {
            const confirmed = window.confirm(`Tem certeza de que deseja excluir a certificação "${certification.certification}"?`);
            if (confirmed) {
                deleteCertificationFromBackend(certification.id);
            }
        }
        const updatedCertifications = formData.certifications.filter(cert => cert.id !== id);

        setFormData({
            ...formData,
            certifications: updatedCertifications
        });
    };

    const removeEmail = (id: number) => {
        const emailObj = formData.emails.find(email => email.id === id);
        if (emailObj && !emailObj.insert) {
            const confirmed = window.confirm(`Tem certeza de que deseja excluir o email "${emailObj.email}"?`);
            if (confirmed) {
                deleteEmailFromBackend(emailObj.id);
            }
        }
        const updatedEmails = formData.emails.filter(email => email.id !== id);
        setFormData({
            ...formData,
            emails: updatedEmails
        });
    };

    const addCertification = () => {
        setFormData({
            ...formData,
            certifications: [
                ...formData.certifications,
                { id: Date.now(), certification: '', certification_date: '', insert: true }
            ]
        });
    };

    const addEmail = () => {
        setFormData({
            ...formData,
            emails: [
                ...formData.emails,
                { id: Date.now(), email: '', insert: true }
            ]
        });
    };

    const handleViewClick = () => {
        onViewMode();
    };

    const handleSubmit = () => {
        axios.put(`${LINKS.API}/profile/${user?.id}`, formData)
            .then(response => {
                if (response.data.status === 200) {
                    toast.success("Informações atualizadas com sucesso.");
                    console.log("Informações atualizadas com sucesso.");
                    onClose();
                    setTimeout(() => window.location.reload(), 1000);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    console.error("Erro ao atualizar informações:", response.data);
                    toast.error("Erro ao atualizar informações.");
                }
            });
    };

    const isAddCertificationDisabled = formData.certifications.some(cert => cert.certification === '' || cert.certification_date === '');
    const isAddEmailDisabled = formData.emails.some(email => email.email === '');

    return (
        <Modal show={isOpen} onHide={onClose} dialogClassName="modal_xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Editar mais informações de {user?.name} {user?.last_name}
                    <FaEye
                        cursor={"pointer"}
                        className="mb-2 mx-2"
                        onClick={handleViewClick}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <section>
                    <h5 className="mb-3">Informações Pessoais</h5>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Data de admissão</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="admission_date"
                                    value={formData.admission_date}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Empresa admitida</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="admitted_company"
                                    value={formData.admitted_company}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>CPF</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="cpf"
                                    value={formData.cpf}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Data de nascimento</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="birth_date"
                                    value={formData.birth_date}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Telefone pessoal</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phone_personal"
                                    value={formData.phone_personal}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Telefone da loja</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phone_store"
                                    value={formData.phone_store}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Nome da mãe</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="mother_name"
                                    value={formData.mother_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Nome do pai</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="father_name"
                                    value={formData.father_name}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>RG</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="rg"
                                    value={formData.rg}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Data de expedição</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="expedition_date"
                                    value={formData.expedition_date}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h5 className="mb-3 mt-3">Informações Bancárias</h5>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Banco</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="bank"
                                    value={formData.bank}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Agência</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="agency"
                                    value={formData.agency}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Conta</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="account"
                                    value={formData.account}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Tipo de Conta</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="account_type"
                                    value={formData.account_type}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>PIX</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="pix"
                                    value={formData.pix}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </section>


                {/* Seção de Certificações */}
                <section>
                    <h5 className="mb-3 mt-3">Certificações</h5>
                    {formData.certifications.map((certification, index) => (
                        <div className="row mb-2" key={certification.id}>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>Certificação</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={certification.certification}
                                        onChange={(e) =>
                                            handleCertificationChange(index, 'certification', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>Data da certificação</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={certification.certification_date}
                                        onChange={(e) =>
                                            handleCertificationChange(index, 'certification_date', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 d-flex align-items-end">
                                <FaTrash
                                    className="text-danger"
                                    cursor={"pointer"}
                                    onClick={() => removeCertification(certification.id)}
                                />
                            </div>
                        </div>
                    ))}
                    <button className="btn btn-primary mt-2" onClick={addCertification} disabled={isAddCertificationDisabled}>
                        <FaPlus /> Adicionar Certificação
                    </button>
                </section>

                {/* Seção de Emails */}
                <section>
                    <h5 className="mb-3 mt-3">Emails</h5>
                    {formData.emails.map((emailObj, index) => (
                        <div className="row mb-2" key={emailObj.id}>
                            <div className="col-md-10">
                                <div className="form-group">
                                    <label>Email {index + 1}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={emailObj.email}
                                        onChange={(e) => handleEmailChange(index, e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 d-flex align-items-end">
                                <FaTrash
                                    className="text-danger"
                                    cursor={"pointer"}
                                    onClick={() => removeEmail(emailObj.id)}
                                />
                            </div>
                        </div>
                    ))}
                    <button className="btn btn-primary mt-2" onClick={addEmail} disabled={isAddEmailDisabled}>
                        <FaPlus /> Adicionar Email
                    </button>
                </section>

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={handleSubmit}>Salvar</button>
            </Modal.Footer>
        </Modal>
    );
}