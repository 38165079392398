import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { Loader } from "../../components/Loader";
import { ILog } from "../../interfaces/ILog";
import { ViewLog } from "../../components/Logs/ViewLog";
import styles from "../../styles/Logs.module.scss";

export const Logs = () => {

    const dispatch = useDispatch();

    const [logs, setLogs] = useState<ILog[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedLog, setSelectedLog] = useState<ILog | undefined>();
    const [isViewLogModalOpen, setIsViewModalLogOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(closeSidebar());
        setIsLoading(true);
        axios.get(`${LINKS.API}/admin/logs`)
            .then(response => {
                if (response.data.status === 200) {
                    setLogs(response.data.data);
                    setIsLoading(false);
                } else {
                    toast.error(response.data.message);
                    console.log(response.data.message);
                }
            })
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false));
    }, [dispatch]);

    const openLog = (log: ILog) => {
        setSelectedLog(log);
        setIsViewModalLogOpen(true);
    }

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">

                <h3>Logs</h3>

            </div>

            <div className={styles.cards}>
                {isLoading ? <Loader size="150px" color="blue" /> : (
                    logs.map((log, index) => (
                        <div onClick={() => openLog(log)} key={index} className="card" style={{ cursor: "pointer" }}>
                            <div className="card-body">
                                <h5 className="card-title">{log.log}</h5>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <ViewLog
                onClose={() => setIsViewModalLogOpen(false)}
                isOpen={isViewLogModalOpen}
                log={selectedLog}
            />
        </div>
    )
}