import { useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import styles from "../../styles/Perfil.module.scss";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { LINKS } from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { Loader } from "../../components/Loader";
import { Button } from "react-bootstrap";
import { ModalUpdateUser } from "../../components/Users/ModalUpdateUser";
import { IUser } from "../../interfaces/IUser";
import toast from "react-hot-toast";
import { MdFolderShared } from "react-icons/md";
import { LineGraph } from "../../components/Graficos/Line";
import classNames from "classnames";
import { TbReportAnalytics } from "react-icons/tb";
import { PieChart } from "../../components/Graficos/Pizza";
import { ModalMoreInfo } from "../../components/Perfil/ModalMoreInfo";
import { FaEye } from "react-icons/fa";
import { ModalExpandData } from "../../components/Perfil/ModalExpandData";

export const Perfil = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [productionGraphData, setProductionGraphData] = useState([]);
    const [efficiencyData, setEfficiencyData] = useState({ closed: 0, not_closed: 0, efficiency: "" });
    const [contactedData, setContactedData] = useState({ contacted: 0, not_contacted: 0, efficiency: "" });
    const [marginData, setMarginData] = useState({ lose_margin: 0, not_lose_margin: 0, efficiency: "" });
    const [perfil, setPerfil] = useState<IUser>();
    const { perfil_id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalMoreInfoOpen, setModalMoreInfoOpen] = useState<boolean>(false);

    const [modalExpandaData, setModalExpandaData] = useState<boolean>(false);
    const [expandDataMode, setExpandDataMode] = useState<"efficiency" | "contacted" | "margin">("efficiency");

    const handleOpenModal = () => {
        setOpenModal(true);
    }
    const currentUser = useSelector(selectCurrentUser);
    useEffect(() => {
        dispatch(closeSidebar());
        const user_id = perfil_id ? perfil_id : currentUser?.id;
        const fetchPerfil = async () => {
            const response = await axios.get(`${LINKS.API}/profile/${user_id}`);

            if (response.data.status === 200) {
                setPerfil(response.data.data);
                setIsLoading(false);

            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }
        const fetchDataGraph = async () => {
            const response = await axios.get(`${LINKS.API}/profile/graph/${perfil_id || currentUser.id}`);

            if (response.data.status === 200) {
                setProductionGraphData(response.data.data.production);
                setEfficiencyData(response.data.data.efficiency);
                setContactedData(response.data.data.contacted);
                setMarginData(response.data.data.margin);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }
        fetchPerfil();
        fetchDataGraph();
    }, [currentUser?.id, dispatch, navigate, perfil_id, currentUser]);


    return (
        isLoading
            ?
            <div className="container"><Loader size="200px" color="blue"></Loader></div>
            :
            <div className="container">
                <div className="d-flex align-items-center titulo gap-3">
                    <h3>Perfil</h3>
                </div>
                <div className={styles.perfil}>
                    <div className={styles.editPerfil}>
                        <img onClick={
                            currentUser.type === "admin" || currentUser.id === perfil?.id ? handleOpenModal : () => { }
                        } src={`${LINKS.ARQUIVOS}${perfil?.photo ? perfil.photo : "/admin/users/user.svg"}`} className={styles.imgPerfil} alt="imagem perfil" />
                        {
                            (currentUser.type === "admin" || currentUser.id === perfil?.id) &&
                            <div className="d-flex gap-2">
                                <Button onClick={handleOpenModal} variant="outline-primary">
                                    EDITAR PERFIL
                                </Button>
                                {
                                    currentUser.type === "admin" &&
                                    <Button onClick={() => { setModalMoreInfoOpen(true) }} variant="outline-primary">
                                        +INFO
                                    </Button>
                                }
                                {
                                    (currentUser.type === "admin" ||
                                        currentUser.type === "operacional" ||
                                        currentUser.id === perfil?.id) &&
                                    <Button onClick={() => { navigate("/relatorios-diarios/" + perfil?.id) }} variant="outline-primary">
                                        <TbReportAnalytics />
                                    </Button>
                                }
                                {
                                    currentUser.type === "admin" &&
                                    <Link className={styles.link_documentos} to={`/perfil/${perfil?.id}/documentos`}>
                                        <Button variant="outline-danger">
                                            <MdFolderShared size={24} />
                                        </Button>
                                    </Link>
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.txtPerfil}>
                        <h1 className={styles.textName}>{perfil?.name && perfil?.name.trim()} {perfil?.last_name && perfil.last_name.trim()}</h1>
                        <h2 className={styles.textEmail}>{perfil?.email}</h2>
                    </div>
                </div>
                {

                }
                {
                    perfil && <ModalUpdateUser onHide={() => setOpenModal(false)} user={perfil} show={openModal} editUser={setPerfil} />
                }
                {
                    currentUser.type === "admin" &&
                    <ModalMoreInfo
                        isOpen={modalMoreInfoOpen}
                        onClose={() => setModalMoreInfoOpen(false)}
                        user={perfil}
                    />
                }
                {
                    perfil?.type === "vendedor" &&
                    (
                        <div className={styles.graficos_section}>
                            <div className="justify-content-center d-flex align-items-center titulo gap-3 mt-5">
                                <h3>Gráficos</h3>
                            </div>
                            <div className={styles.graficos}>
                                <div className={classNames(styles.grafico, "card")}>
                                    <div className={styles.grafico_header}>
                                        <h5 className={styles.grafico_titulo}>
                                            Produção
                                            <span>
                                                (total: R$ {productionGraphData.reduce((acc: number, item: any) => acc + item.valor, 0).toLocaleString('pt-BR')})
                                            </span>
                                        </h5>

                                    </div>
                                    <LineGraph
                                        datasets={[
                                            {
                                                label: "Produção",
                                                data: productionGraphData.map((item: any) => item.valor),
                                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                                borderColor: 'rgba(54, 162, 235, 1)',
                                                borderWidth: 1,
                                                fill: true,
                                                tension: 0.3
                                            }
                                        ]}
                                        data_type="money"
                                        labels={productionGraphData.map((item: any) => item.production_month)}
                                    />


                                </div>

                                {
                                    currentUser.type === "admin" &&
                                    (<>
                                        <div className={classNames(styles.grafico, "card")}>
                                            <div className={styles.grafico_header}>
                                                <h5 className={styles.grafico_titulo}>
                                                    Aproveitamento das bases
                                                    <span>
                                                        ({efficiencyData.efficiency}%)
                                                    </span>
                                                </h5>
                                                <FaEye cursor={"pointer"} onClick={() => {
                                                    setExpandDataMode("efficiency");
                                                    setModalExpandaData(true);
                                                }} />
                                            </div>
                                            <div className={styles.pie_chart}>
                                                <PieChart
                                                    labels={['Fechadas', 'Não fechadas']}
                                                    datasets={[{
                                                        label: 'Aproveitamento das bases',
                                                        data: [efficiencyData.closed, efficiencyData.not_closed],
                                                        backgroundColor: ['#36A2EB', '#FF6384']
                                                    }]}
                                                    show_legend={false}
                                                />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.grafico, "card")}>
                                            <div className={styles.grafico_header}>
                                                <h5 className={styles.grafico_titulo}>
                                                    Clientes contatados
                                                    <span>
                                                        ({contactedData.efficiency}%)
                                                    </span>
                                                </h5>
                                                <FaEye cursor={"pointer"} onClick={() => {
                                                    setExpandDataMode("contacted");
                                                    setModalExpandaData(true);
                                                }} />
                                            </div>
                                            <div className={styles.pie_chart}>
                                                <PieChart
                                                    labels={['Contatados', 'Não contatados']}
                                                    datasets={[{
                                                        label: 'Clientes contatados',
                                                        data: [contactedData.contacted, contactedData.not_contacted],
                                                        backgroundColor: ['#36A2EB', '#FF6384']
                                                    }]}
                                                    show_legend={false}
                                                />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.grafico, "card")}>
                                            <div className={styles.grafico_header}>
                                                <h5 className={styles.grafico_titulo}>
                                                    Margens perdidas
                                                    <span>
                                                        ({marginData.efficiency}%)
                                                    </span>
                                                </h5>
                                                <FaEye cursor={"pointer"} onClick={() => {
                                                    setExpandDataMode("margin");
                                                    setModalExpandaData(true);
                                                }} />
                                            </div>
                                            <div className={styles.pie_chart}>
                                                <PieChart
                                                    labels={['Não perdidas', 'Perdidas']}
                                                    datasets={[{
                                                        label: 'Margens perdidas',
                                                        data: [marginData.not_lose_margin, marginData.lose_margin],
                                                        backgroundColor: ['#36A2EB', '#FF6384']
                                                    }]}
                                                    show_legend={false}
                                                />
                                            </div>
                                        </div>
                                        <ModalExpandData
                                            isOpen={modalExpandaData}
                                            onClose={() => setModalExpandaData(false)}
                                            mode={expandDataMode}
                                            seller={perfil}
                                        />
                                    </>)
                                }
                            </div>
                        </div>
                    )
                }
            </div>
    )
}

