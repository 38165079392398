import { FaEdit } from "react-icons/fa";
import { IUser } from "../../interfaces/IUser";
import toast from "react-hot-toast";
import axios from "axios";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import classNames from "classnames";
import styles from "../../styles/Users.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    user: IUser;
    editUser: (user: IUser) => void;
    handleUpdateModal: (user: IUser) => void;
}

export const User = ({ user, editUser, handleUpdateModal }: Props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleEdit = () => {
        handleUpdateModal(user);
    };

    const handleBlock = (id: number, action: number) => {
        const confirmDelete = window.confirm(`Deseja realmente ${action === 1 ? "des" : ""}bloquear este usuário?`);
        if (confirmDelete) {
            axios.put(`${LINKS.API}/users/${action === 1 ? 'un' : ''}block/${id}`)
                .then(response => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message);
                        editUser(response.data.data);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    console.error(error);
                    toast.error("Erro ao excluir usuário");
                });
        }
    }

    return (
        <tr className={user.ativo === 0 ? styles.inativo : ""}>
            <th scope="row">{user.id}</th>
            <td>
                <Link to={`/perfil/${user.id}`}>
                    {`${user.name.trim()} ${user.last_name ? user.last_name.trim() : ''}`}
                </Link>
            </td>
            <td>{user.email}</td>
            <td>{user.type}</td>
            {
                user.type !== "robot"
                    ? (
                        <td title="Editar Acesso" className={classNames("text-center", styles.botoes)}>
                            <FaEdit size={20} color="black" cursor="pointer" onClick={handleEdit} />
                        </td>
                    )
                    :
                    (<td></td>)
            }

            {
                user.type !== "robot" ? (

                    user.ativo === 1
                        ?
                        (
                            <td title="Bloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaLock size={20} color="black" cursor="pointer" onClick={() => handleBlock(user.id, 0)} />
                            </td>
                        )
                        :
                        (
                            <td title="Desbloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaUnlock size={20} color="black" cursor="pointer" onClick={() => handleBlock(user.id, 1)} />
                            </td>
                        )

                ) : (<td></td>)
            }
            {/* <td>{user.photo}</td> */}
            {/* <td>{user.status}</td>
            <td>{user.created_at}</td>
            <td>{user.updated_at}</td> */}

        </tr>
    )
}
