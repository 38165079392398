import { Modal, Button } from "react-bootstrap"
import { IoTrashOutline } from "react-icons/io5"
import { IBank } from "../../interfaces/IBank";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    bank: IBank;
    show: boolean;
    onHide: () => void;
    handleDeleteBank: (bank: IBank) => void;
}

export const ModalDeleteBank = ({ bank, show, onHide, handleDeleteBank }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const confirmDelete = () => {
        axios.delete(`${LINKS.API}/banks/${bank.id}`).then(response => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                handleDeleteBank(bank);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error('Erro ao excluir Banco');
            }
            onHide();
        }).catch(error => {
            toast.error('Erro ao excluir Banco');
            console.error(error);
        });
    }

    if (!bank) return null;

    return (
        <Modal show={show} onHide={onHide} closeButton>
            <Modal.Header>
                <Modal.Title>Excluir Banco</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Deseja realmente excluir o banco <strong>{bank.name.trim()}</strong>?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar</Button>
                <Button variant="danger" onClick={confirmDelete}>
                    Excluir <IoTrashOutline /></Button>
            </Modal.Footer>
        </Modal>
    )
}