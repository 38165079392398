import { useEffect, useState } from "react";
import { IBase, IBaseCustomer } from "../../interfaces/IBase";
import axios from "axios";
import { LINKS, formatCpf } from "../../config";
import { Loader } from "../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../NotFound";
import { useSelector, useDispatch } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { IProposal } from "../../interfaces/IProposal";
import styles from "../../styles/Customers.module.scss";
import { ViewBase } from "../../components/Bases/ViewBase";
import { FaEdit, FaEye } from "react-icons/fa";
import { VisualizarProposta } from "../../components/Customers/VisualizarProposta";
import toast from "react-hot-toast";
import { ModalUpdateCustomer } from "../../components/Customers/ModalUpdateCustomer";


export const Customer = () => {

    const [customer, setCustomer] = useState<IBaseCustomer | null | undefined>(undefined);
    const { customer_id } = useParams();
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [bases, setBases] = useState<IBase[]>([]);
    const [proposals, setProposals] = useState<IProposal[]>([]);
    const [viewBaseOpen, setViewBaseOpen] = useState<boolean>(false);
    const [viewBase, setViewBase] = useState<IBase>();
    const [viewProposalOpen, setViewProposalOpen] = useState<boolean>(false);
    const [viewProposal, setViewProposal] = useState<IProposal>();
    const [openModalUpdateCustomer, setOpenModalUpdateCustomer] = useState(false);

    useEffect(() => {
        axios.get(`${LINKS.API}/customers/data/${customer_id}`)
            .then((response) => {
                if (response.data.status === 200) {
                    setCustomer(response.data.data.customer);
                    setBases(response.data.data.bases);
                    setProposals(response.data.data.proposals);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                } else {
                    setCustomer(null);
                    toast.error("Error fetching customer data:", response.data.message);
                    console.error("Error fetching customer data:", response.data.message);
                }
            }).catch((error) => {
                setCustomer(null);
                toast.error("Error fetching customer data:", error);
                console.error("Error fetching customer data:", error);
            });
    }, [customer_id, currentUser, dispatch, navigate]);

    if (customer === null) return <NotFound />;

    return (
        <div className="container">
            {customer ? (<>
                <div className="d-flex align-items-center titulo gap-3">
                    <h3>{customer.name}</h3>
                    {
                        currentUser?.type === "admin" && (
                            <>
                                <FaEdit
                                    size={20}
                                    style={{ cursor: "pointer", marginBottom: "15px" }}
                                    onClick={() => setOpenModalUpdateCustomer(true)}
                                />
                            </>
                        )
                    }
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <td><strong>ID</strong></td>
                            <td>{customer.id}</td>
                        </tr>
                        <tr>
                            <td><strong>Nome</strong></td>
                            <td>{customer.name}</td>
                        </tr>
                        <tr>
                            <td><strong>CPF</strong></td>
                            <td>{formatCpf(customer.cpf)}</td>
                        </tr>
                        <tr>
                            <td><strong>Telefone</strong></td>
                            <td>{customer.phone}</td>
                        </tr>
                        <tr>
                            <td><strong>Idade</strong></td>
                            <td>{customer.age} {customer.age !== '0' && "anos"}</td>
                        </tr>
                    </thead>
                </table>

                <div className={styles.bases}>
                    <h4>Bases</h4>
                    {
                        bases.length > 0 ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Base</th>
                                        <th>Enviado</th>
                                        <th>Distribuido</th>
                                        <th>Visualizar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bases.map((base, index) => (
                                        <tr key={index}>
                                            <td>{base.id}</td>
                                            <td>{base.name}</td>
                                            <td>{base.sent_at}</td>
                                            <td>{base.distributed_at}</td>
                                            <td className={styles.details}>
                                                <FaEye
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        setViewBase(base);
                                                        setViewBaseOpen(true);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : "Nenhuma base encontrada."
                    }
                </div>

                <div className={styles.proposals}>
                    <h4>Propostas</h4>
                    {
                        proposals.length > 0 ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Data da proposta</th>
                                        <th>Contrato</th>
                                        <th>Proposta</th>
                                        <th>Banco</th>
                                        <th>Valor de parcela</th>
                                        <th>Valor liberado</th>
                                        <th>Visualizar</th>
                                        {/* <th>Trocar</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {proposals.map((proposal, index) => (
                                        <tr key={index}>
                                            <td>{proposal.id}</td>
                                            <td>{proposal.proposal_date}</td>
                                            <td>{proposal.contract}</td>
                                            <td>{proposal.proposal}</td>
                                            <td>{proposal.bank}</td>
                                            <td className={styles.text_right}>R$ {proposal.parcel_value.toLocaleString("pt-BR")}</td>
                                            <td className={styles.text_right}>R$ {proposal.released_value.toLocaleString("pt-BR")}</td>
                                            <td className={styles.details}>
                                                <FaEye
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        setViewProposal(proposal);
                                                        setViewProposalOpen(true);
                                                    }}
                                                />
                                            </td>
                                            {/* <td className={styles.details}>
                                                <HiSwitchVertical
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        setProposalToSwitch(proposal);
                                                        setOpenModalSwitchBaseProposal(true);
                                                    }}
                                                    title="Trocar base"
                                                />
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : "Nenhuma proposta encontrada."
                    }
                </div>
                {bases.length > 0 && <ViewBase onHide={() => setViewBaseOpen(false)} isOpen={viewBaseOpen} base={viewBase || bases[0]} />}
                {proposals.length > 0 && <VisualizarProposta onHide={() => setViewProposalOpen(false)} isOpen={viewProposalOpen} proposal={viewProposal || proposals[0]} />}
                {/* <ModalSwitchBaseProposal
                    setProposals={setProposals}
                    isOpen={openModalSwitchBaseProposal}
                    onHide={() => setOpenModalSwitchBaseProposal(false)}
                    proposal={proposalToSwitch}
                /> */}
                <ModalUpdateCustomer
                    show={openModalUpdateCustomer}
                    onHide={() => setOpenModalUpdateCustomer(false)}
                    customer={customer}
                    onUpdate={(customer) => setCustomer(customer)}
                />
            </>) : <Loader color="blue" size="150px" />}
        </div>
    );
}
