import { Modal, Table } from "react-bootstrap";
import { IBank, IImportantPhone } from "../../interfaces/IBank";
import { useEffect, useState } from "react";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { logOut } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader";
import { PatternFormat } from "react-number-format";

interface Props {
    bank: IBank | undefined;
    show: boolean;
    onHide: () => void;
    canEdit: boolean;
}

export const ModalImportantPhones = ({ bank, show, onHide, canEdit }: Props) => {

    const [importantPhones, setImportantPhones] = useState<IImportantPhone[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        setIsLoading(true);
        if (show) {
            axios.get(`${LINKS.API}/banks/${bank?.id}/important-phones`)
                .then(response => {
                    if (response.data.status === 200) {
                        setImportantPhones(response.data.data);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => console.log(error))
                .finally(() => setIsLoading(false));
        }

    }, [bank, show, dispatch, navigate])

    const [newPhone, setNewPhone] = useState("");
    const [newPhoneDescription, setNewPhoneDescription] = useState("");

    const submitNewPhone = (e: any) => {
        e.preventDefault();

        if (newPhone === "") {
            toast.error("Informe o telefone");
            return;
        }

        if (newPhoneDescription === "") {
            toast.error("Informe a descrição");
            return;
        }

        axios.post(`${LINKS.API}/banks/important-phones`, {
            phone: newPhone,
            description: newPhoneDescription,
            bank_id: bank?.id
        }).then(response => {
            if (response.data.status === 201) {
                toast.success(response.data.message);
                setNewPhone("");
                setNewPhoneDescription("");
                setImportantPhones([...importantPhones, response.data.data]);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => console.log(error));

    }

    return (
        <Modal show={show} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Telefone Importantes - {bank?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? <div className="w-100 d-flex align-items-center justify-content-center"><Loader size="150px" color="blue" /></div> :
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Telefone</th>
                                    <th>Descrição</th>
                                </tr>
                            </thead>
                            <tbody>
                                {importantPhones.map(phone => (
                                    <tr key={phone.id}>
                                        <td>{phone.id}</td>
                                        <td>{phone.phone}</td>
                                        <td>{phone.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                }
                {canEdit && <>
                    <hr />
                    <h4>Adicionar Telefone</h4>
                    <div className="d-flex justify-content-end mt-3 gap-3">
                        <form onSubmit={submitNewPhone} className="w-100">
                            <PatternFormat
                                format="(##) #####-####"
                                value={newPhone}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setNewPhone(value);
                                }}
                                className="form-control mb-2"
                                placeholder="Telefone"
                            />
                            <input
                                className="form-control w-100 mb-2"
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Descrição do Telefone"
                                value={newPhoneDescription}
                                onChange={(e) => setNewPhoneDescription(e.target.value)}
                            />
                            <button style={{ float: "right" }} type="submit" className="btn btn-primary">Adicionar</button>
                        </form>
                    </div>

                </>}
            </Modal.Body>
        </Modal >
    )
}