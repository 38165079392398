import { useEffect, useState } from "react";
import { INotificationWithUser } from "../../pages/ManageNotifications/ManageNotifications";
import { IUser } from "../../interfaces/IUser";
import { Form, Modal } from "react-bootstrap";
import axios from "axios";
import { LINKS } from "../../config";
import styles from "../../styles/Notifications.module.scss";
import { Loader } from "../Loader";

interface Props {
    show: boolean;
    onHide: () => void;
    handleNewNotification: (notification: INotificationWithUser) => void;
}

export const ModalNewNotification = ({ show, onHide, handleNewNotification }: Props) => {

    const [users, setUsers] = useState<IUser[]>([]);

    const [selectedUser, setSelectedUser] = useState<number | null>(null);
    const [message, setMessage] = useState<string>("");
    const [link, setLink] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [duplicate, setDuplicate] = useState<boolean>(false);
    const [duplicateId, setDuplicateId] = useState<number | null>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectedUser || !message) {
            alert("Preencha todos os campos obrigatórios!");
            return;
        }

        setIsLoading(true);

        axios.post(`${LINKS.API}/notifications`, {
            user_id: selectedUser,
            message,
            link
        }).then((response) => {
            if (response.data.status === 201) {
                handleNewNotification(response.data.data);
                setSelectedUser(null);
                setMessage("");
                setLink("");
                setIsLoading(false);
                onHide();
            }
        }).catch((error) => { console.log(error); setIsLoading(false); });
    };

    const duplicateNotification = () => {

        setIsLoading(true);

        axios.get(`${LINKS.API}/notifications/duplicate/${duplicateId}`).then((response) => {
            setSelectedUser(response.data.user_id);
            setMessage(response.data.message);
            setLink(response.data.link);
            setIsLoading(false);
        }).catch((error) => { console.log(error); setIsLoading(false); });

    };

    useEffect(() => {
        if (show) {
            setIsLoading(true);
            axios.get(`${LINKS.API}/users`).then((response) => {
                if (response.data.status === 200) {
                    setUsers(response.data.data);
                }
                setIsLoading(false);
            }).catch((error) => { console.log(error); setIsLoading(false); });
        }
    }, [show]);

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Criar nova notificação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>

                    <Form.Group className="mb-3">
                        <Form.Label>Duplicar notificação?</Form.Label>
                        <Form.Check
                            type="checkbox"
                            checked={duplicate}
                            onChange={(e) => setDuplicate(e.target.checked)}
                        />
                        {duplicate && (<>
                            <Form.Control name="duplicate" placeholder="ID da notificação" type="number" value={duplicateId || 0} onChange={(e) => setDuplicateId(parseInt(e.target.value))} />
                            <span className={styles.duplicate_btn} onClick={duplicateNotification}>duplicar</span>
                        </>)}
                    </Form.Group>

                    {
                        isLoading ? (
                            <Loader color="blue" size="100px" />
                        ) :
                            (<>
                                <Form.Group className="mb-3">
                                    <Form.Label>Usuário</Form.Label>
                                    <Form.Select
                                        value={selectedUser || 0}
                                        onChange={(e) => setSelectedUser(parseInt(e.target.value))}
                                    >
                                        <option value={0}>Selecione um usuário</option>
                                        {users.map((user) => (
                                            <option key={user.id} value={user.id}>{user.name} {user.last_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Mensagem</Form.Label>
                                    <Form.Control
                                        name="message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Link</Form.Label>
                                    <Form.Control
                                        name="link"
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                    />
                                </Form.Group>
                            </>)
                    }

                    <div style={{ float: "right" }}>
                        <button type="submit" className="btn btn-primary mt-3">Salvar</button>
                        <button type="button" className="btn btn-secondary mt-3 ms-2" onClick={onHide}>Cancelar</button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    )
};